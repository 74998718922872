@import './helpers';

.menu-bar-component {
  display: flex;
  width: calc(100% - 30px);
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #d9d9d9;
  border-radius: 7px;
  margin: 15px;
  max-width: 600px;

  .menu-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33%;

    .menu-bar__logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .okeep-logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: 70px;
        height: 100%;
        object-fit: contain;
      }
    }

    .menu-bar__title {
      font-family: $font-family-semi-bold;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: $color-black;
    }

    &.active {
      border-bottom: 4px solid #ffbb21;
      background-color: #a8a8a8;
      padding: 3px 0;
      border-radius: 7px;
    }
  }

  .menu-bar__border {
    border-right: 1px solid $color-white;
  }
}

.service-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px;

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    .service__title {
      font-family: $font-family-semi-bold;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: $color-white;
      text-align: center;
    }

    .service__image {
      width: calc(var(--aspect-ratio) * 200px);
      height: calc(var(--aspect-ratio) * 200px);
      max-width: 90px;
      max-height: 90px;
      object-fit: cover;
      margin-bottom: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      background-color: white;
    }
  }
}

.header-component {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.49);
  border-radius: 12px;

  .header-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 70px);
    height: calc(100% - 4px);
    border-bottom: 2px solid $color-secondary;

    .header-body__title {
      font-family: $font-family-bold;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: $color-white;
    }

    .header-body__subtitle {
      font-family: $font-family-semi-bold;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: $color-white;
    }

    .clickable {
      cursor: pointer;
      font-family: $font-family-light;
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      color: $color-white;
      text-decoration: underline;
    }
  }

  .back-button,
  .home-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 100%;
    background: $color-black;
    cursor: pointer;
    margin: 15px 0;

    img {
      height: 25px;
    }
  }

  .back-button {
    border-radius: 12px 0 0 12px;
  }

  .home-button {
    border-radius: 0 12px 12px 0;
  }
}

.description-box {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .description-box__title {
    font-family: $font-family-bold;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: $color-white;
    margin-bottom: 5px;
  }

  .description-box__sub-title {
    font-family: $font-family-medium;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $color-white;
    margin-bottom: 5px;
  }

  .description-box__description {
    background: #464646;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    padding: 15px 30px;
    @include subTitleFontStyle();

    .description-box__header,
    .description-box__body {
      font-family: $font-family-medium;
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      color: $color-white;

      .bold {
        font-family: $font-family-bold;
        font-weight: 700;
      }
    }

    .description-box__header {
      margin-bottom: 15px;
    }
  }
}

.info-box {
  width: 90%;
  margin: 10px 0;

  .info-box__description {
    @include subTitleFontStyle();
  }
}

.request-button {
  background: #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 90%;
  display: flex;
  align-items: center;
  margin: 5px;
  position: relative;

  &.hidden {
    display: none;
  }

  .request-button__icon {
    width: 28px;
    height: 28px;
    margin: 0 5px;
    padding: 6px;
  }

  .request-button__title {
    @include subTitleFontStyle();
    padding: 6px;
    position: absolute;
    width: calc(100% - 12px);
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  .date-picker__input {
    width: 100%;
  }

  .date-picker__title {
    @include titleFontStyle();
    margin-bottom: 10px;
    text-align: center;
  }

  .date-picker__description {
    font-family: $font-family-medium;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: $color-white;
    margin-top: 10px;
  }
}

.info-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;

  .info-bottom__title {
    @include subTitleFontStyle();
    margin-bottom: 3px;
  }

  .info-bottom__contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 12px 12px;
    padding: 5px 0;
  }
}

.price-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 5px 0;

  .price-info__text {
    @include subTitleFontStyle();
  }

  .price-box {
    display: flex;
    flex-direction: column;

    .price-box__usd {
      font-family: $font-family-bold;
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;
      color: $color-white;
    }

    .price-box__try {
      font-family: $font-family-medium;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      color: $color-white;
    }
  }
}

.next-step-button {
  width: 75%;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffbb21;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin: 10px 0;
  max-width: 560px;
  min-height: 48px;

  .next-step-button__text {
    @include subTitleFontStyle();
    margin-left: 15px;
  }

  .next-step-button__icon {
    margin-right: 15px;
    display: flex;

    img {
      height: 25px;
    }
  }
}

.payment-info {
  background: #464646;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 137px;
  height: 137px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;

  .payment-info__title {
    font-family: $font-family-medium;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $color-white;
    text-decoration: underline;
  }

  .payment-info__usd {
    font-family: $font-family-bold;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    color: $color-white;
  }

  .payment-info__try {
    font-family: $font-family-medium;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $color-white;
  }
}

.request-info {
  margin: 10px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 95%;

  .request-info__text {
    @include subTitleFontStyle();
    padding: 10px;

    span {
      color: #ffbb21;
    }
  }
}

.vehicle {
  width: 90%;
  margin: 5px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #2c2c2c;
  border-radius: 12px;

  .vehicle__icon {
    padding: 12px;
    width: 35px;
    height: 35px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .vehicle__info {
    display: flex;
    flex-direction: column;
    font-family: $font-family-light;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: $color-white;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;

    .vehicle__info__title {
      @include subTitleFontStyle();
      color: #ffbb21;
    }

    .vehicle__info__group {
      display: flex;
    }

    .vehicle__info__icon__text {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .vehicle__info__icon {
        padding: 0 3px 0 0;
        width: 15px;
        height: 15px;
      }
    }
  }
}

.text-button {
  width: calc(90% - 14px);
  margin: 5px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  .text-button__title {
    @include titleFontStyle();
  }

  .text-button__sub-title {
    font-family: $font-family-medium;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: $color-white;
  }
}

.transfer-info {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  font-family: $font-family-semi-bold;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  color: $color-white;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 12px 12px;
  position: absolute;
  bottom: 0;

  .transfer-info__group {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
  }

  .transfer-info__box {
    display: flex;

    .transfer-info__box-icon {
      padding: 0 5px 0 0;
      width: 14px;
      height: 14px;
    }
  }
}

.slide-container {
  width: 100%;
  height: 150px;
  margin: auto;
  margin-bottom: 10px;

  .each-slide {
    .lazy {
      object-fit: cover;
      border-radius: 8px;
      width: 100%;
      height: 150px;
    }
  }
}

.guidance-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 5px;
  position: absolute;
  bottom: 0;

  .guidance-button-group__text-button {
    padding: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2c2c2c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: calc(100% - 60px);
    height: 25px;

    .guidance-button-group__text-button__title {
      @include subTitleFontStyle();
    }

    .guidance-button-group__text-button__description {
      font-family: $font-family-light;
      font-size: 10px;
      line-height: 12px;
      font-weight: 300;
      color: $color-white;
    }
  }

  .guidance-button-group__icon-button {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
  }
}

.close-header-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.49);
  border-radius: 12px 12px 0px 0px;
  border-bottom: 2px solid #ffbb21;
  position: relative;

  .close-header-component__icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }

  .close-header-component__title {
    font-family: $font-family-bold;
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    color: $color-white;
    padding: 15px;
    width: calc(100% - 110px);
    text-align: center;
  }

  .close-header-component__close-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.working-hours-box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .working-hours-box__description {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    height: 85%;
    background: #464646;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    .working-hours-box__description__day {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .working-hours-box__description__day__title,
      .working-hours-box__description__day__time {
        font-family: $font-family-bold;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: $color-white;
        padding: 9px;
      }
    }
  }

  .working-hours-box__divider {
    width: 100%;
    height: 2px;
    background: #adadad;
  }
}

.map-container {
  width: calc(100% - 12px);
  height: calc(100% - 60px);
  margin: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .map-container__map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .map-container__map__marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cart-button {
  width: 90%;
  height: 40px;
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  .cart-button__title {
    font-family: $font-family-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: $color-white;
  }

  .cart-icon__container {
    position: absolute;
    right: 10px;

    .cart-icon__container-count {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 2px;
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      color: $color-white;
    }
  }
}

.text-input-container {
  width: 80%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-input {
    width: 100%;

    input {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }

    ::placeholder,
    input {
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      color: $color-black;
    }
  }
}

.counter-input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  width: 90%;

  .counter-input-title {
    font-family: $font-family-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: $color-white;
    margin-bottom: 5px;
  }

  .counter-input-group__counters {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2b2b2b;
    border-radius: 12px;
    padding: 10px;

    .counter-input-group__counter {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;

      label {
        font-family: $font-family-bold;
        font-size: 12px;
        line-height: 15px;
        font-weight: 700;
        color: $color-white;
        text-align: center;
        margin-bottom: 5px;
      }

      :nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;

        :first-child {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            color: white;
            width: 15px;
            height: 15px;
            right: -5px;
            background-color: transparent;
          }
        }

        :nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          padding: 0;
          background: #ffffff;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 2px;
        }

        :last-child {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            color: white;
            width: 15px;
            height: 15px;
            left: -5px;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 10px;

  .checkbox-container__title {
    font-family: $font-family-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: $color-white;
  }

  .checkbox-container__checkbox {
    .rainbow-checkbox-toggle_faux {
      height: 15px;
      width: 35px;

      &::after {
        height: 12px;
        width: 12px;
        left: 1.5px;
        background-color: $color-black;
      }
    }
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--inner-height);

  .service-extra-package__content {
    width: 90%;
    height: 100%;
    background: #575757;
    border-radius: 12px;
    position: relative;
  }
}

.extra-packages-box {
  margin-top: 10px;
  height: calc(100% - 150px);
  overflow: scroll;

  .extra-package-container {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;

    .package-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #2c2c2c;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 1px solid #ffffff;
      transform: rotate(0deg);
      padding: 3px 10px;

      .extra-package-container__title {
        @include subTitleFontStyle();
        color: #ffbb21;
      }

      .extra-package-container__price {
        font-family: $font-family-bold;
        font-size: 12px;
        line-height: 15px;
        font-weight: 700;
        color: $color-white;
      }
    }

    .extra-package-container__info-box {
      display: flex;
      align-items: center;

      .extra-package-container__info-box__radio-button {
        display: flex;
        justify-content: center;

        .rainbow-input_faux {
          border-radius: 50%;
          margin-right: 5px;
          border: none;
          border-inline: 2px solid;

          &::after {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
          }
        }
      }

      .extra-package-container__info-box__body {
        font-family: $font-family-medium;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        color: $color-white;
        background: #464646;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0 0 5px 5px;
        padding: 5px;
      }
    }
  }
}

.okey-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff4954;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 90%;
  height: 40px;
  margin: 10px 5%;
  position: absolute;
  bottom: 5px;

  .okey-button__title {
    font-family: $font-family-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: $color-white;
  }
}

.procedure-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .procedures {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .procedure-group {
      display: flex;
      align-items: center;
      justify-content: baseline;
      width: 100%;

      .procedure-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        background: #2c2c2c;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        margin: 5px 10px;

        .procedure-container__icon {
          width: 66px;
          height: 66px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .procedure-container__title {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-family: $font-family-bold;
          font-size: 12px;
          line-height: 15px;
          font-weight: 700;
          color: $color-white;
          width: 97%;
        }
      }
    }
  }
}

.bottom-button-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  height: 576px;
  background: #575757;
  border-radius: 12px;

  &:hover,
  &:focus {
    outline: none;
  }

  .modal-body {
    width: 100%;
    height: calc(100% - 120px);
    overflow: scroll;

    .modal-comp__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;

      .modal-comp__body-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 5%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 12px;
        }
      }

      .modal-comp__body-text {
        background: #464646;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 14px;
        width: calc(90% - 20px);
        height: 100%;
        font-family: $font-family-medium;
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        color: $color-white;
        padding: 10px;
        overflow: scroll;
      }
    }
  }
}

.app-box {
  display: flex;
  align-items: center;
  width: 90%;
  height: 60px;
  background: #2c2c2c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-top: 10px;

  .app-box__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px 0px 0px 6px;
    margin-right: 10px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .app-box__info-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: calc(100% - 70px);
    height: 100%;
    margin-right: 5px;

    .app-box__info-box__title {
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      color: $color-white;
    }

    .app-box__info-box__description {
      font-family: $font-family-light;
      font-size: 10px;
      line-height: 12px;
      font-weight: 400;
      color: $color-white;
    }
  }
}

.useful-info-componet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  .useful-info-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 10px;

    .useful-info-image-container__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  .useful-info-description {
    font-family: $font-family-semi-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: $color-white;
    margin-bottom: 10px;
  }
}

.download-button-group {
  width: 270px;
  height: 47px;
  position: absolute;
  top: 545px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 100%;
    background: #2c2c2c;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 10px;

    .download-button__text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .download-button__title {
        font-family: $font-family-bold;
        font-size: 12px;
        line-height: 15px;
        font-weight: 700;
        color: $color-white;
      }

      .download-button__description {
        font-family: $font-family-medium;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        color: $color-white;
      }
    }

    .download-button__icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin-left: 10px;

      .download-button__icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.bottom-info-container {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 12px 12px;
    font-family: $font-family-semi-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: $color-white;
    padding: 10px;
    margin-top: 10px;
  }
}

.massage-info-componet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  .massage-info-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;

    .massage-info-image-container__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .image-footer-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: 0;
      background-color: #ffbb21;
      border-radius: 0 0 12px 12px;
      padding: 2px 0;

      .image-footer-info__title {
        font-family: $font-family-bold;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        color: $color-white;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .massage-info-description {
    font-family: $font-family-semi-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: $color-white;
    margin-bottom: 10px;
  }
}

.time-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 5px;

  .time-info__title {
    font-family: $font-family-bold;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: $color-white;
  }

  .time-info__time {
    font-family: $font-family-bold;
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    color: $color-white;
  }
}

.more-information-container {
  display: flex;
  align-items: center;
  width: 80%;
  margin-top: 10px;

  .info-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .more-information-text {
    font-family: $font-family-semi-bold;
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    color: $color-white;
  }
}

.warning {
  justify-content: center;

  .description {
    text-align: center;
    font-family: $font-family-semi-bold;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: $color-white;
    padding: 20px 10px;
  }
}

.custom-text-area {
  width: 100%;
  height: 100%;

  :first-child {
    height: 100%;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    border: none;
    font-family: $font-family-bold;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #a6a6a6;
    text-decoration: underline;
  }

  textarea {
    box-sizing: border-box !important;
    height: 100%;
    padding: 10px;

    &:focus,
    &:active {
      outline: none;
      padding: 10px;
      border: 1px solid #ffbb21;
    }

    &::placeholder {
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      color: #a6a6a6;
      text-decoration: underline;
    }
  }
}

.discount-code-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  writing-mode: vertical-lr;
  text-orientation: mixed;
  white-space: nowrap;
  background-color: #ffbb21;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  border-radius: 0 6px 6px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .discount-code-button__title {
    font-family: $font-family-bold;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #575757;
  }
}

.breakfast-info-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .breakfast-info__image {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  .breakfast-info__text {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    .breakfast-info__text__title {
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      color: $color-white;
      margin: 5px;
      text-align: center;
    }

    .breakfast-info__text__discount {
      font-family: $font-family-bold;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: $color-white;
      text-align: center;
    }

    .discount-code {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .discount-code__label {
        font-family: $font-family-regular;
        font-size: 10px;
        line-height: 13px;
        font-weight: 600;
        color: $color-white;
        margin-bottom: 5px;
      }

      input {
        height: 30px;
      }
    }

    .code-share {
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      color: $color-white;
      margin-top: 5px;
    }
  }
}

.footer-component {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #d9d9d9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 600px;
  margin-top: 20px;
  position: relative;
  bottom: 0;

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33%;

    .footer__logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .footer__title {
      font-family: $font-family-semi-bold;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: $color-black;
    }

    &.active {
      border-bottom: 4px solid #ffbb21;
      background-color: #a8a8a8;
      padding: 3px 0;
      border-radius: 7px;
    }
  }

  .footer__border {
    border-right: 2px solid $color-white;
  }
}

.wifi-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
  }

  &__title {
    font-family: $font-family-bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: white;
    text-align: center;
    margin: 10px;
    margin-top: 0;
  }

  &__input {
    margin: 10px 0;
    label {
      font-family: $font-family-bold;
      font-size: 14px;
      line-height: normal;
      font-weight: 700;
      color: $color-white;
      margin-bottom: 5px;
    }

    input {
      border-radius: 9px;
      background: #464646 !important;
      color: $color-white !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
    }
  }
}

.reservation-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
  align-items: center;
  justify-content: space-around;

  &__img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img-title {
    font-family: $font-family-bold;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 8px;
    width: 95%;
    text-align: center;
    min-height: 30px;
  }

  img {
    width: 95%;
    border-radius: 8px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin-bottom: 8px;
  }

  &__label {
    color: rgba(255, 255, 255, 1);
    font-family: $font-family-bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    margin-bottom: 4px;
  }
  &__input {
    border-radius: 9px;
    background: #464646;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    width: 100%;
    color: #fff;
    font-family: $font-family-bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 44px;

    .date {
      padding: 0px 0px 0px 20px;
    }
  }
  &__button {
    color: #000;
    text-align: center;
    font-family: $font-family-bold;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    background-color: rgba(255, 187, 33, 1);
    border-radius: 6px;
    height: 20px;
    padding: 0;
  }

  &__id {
    color: #fff;
    text-align: center;
    font-family: $font-family-bold;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .info-boxs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.late-check-out-container {
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.offers-content {
  display: flex;
  align-items: center;

  img {
    width: 90px;
    height: 100px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
  }

  .offers-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 10px;
    height: 100px;
  }

  &__title {
    color: #fff;
    font-family: $font-family-bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__discount {
    width: 100%;
    color: #ffbb21;
    font-family: $font-family-bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.content-body-1 {
  width: 75%;
  background: $color-primary;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  max-width: 560px;
  border-radius: 12px;
  margin-bottom: 10px;
}

.line-24 {
  border: 2px solid #ffbb21;
  width: 75%;
  background: #ffbb21;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  max-width: 560px;
}

.language-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__languages {
    width: 85%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  &__language {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin: 5px;
  }
  &__group {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  img {
    width: 85px;
    height: 85px;
    flex-shrink: 0;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 50%;
  }

  span {
    color: #fff;
    text-align: center;
    font-family: $font-family-bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-active {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.swiper-slide-prev {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.swiper-slide-next {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

:root {
  --swiper-theme-color: #ffbb21 !important;
  --swiper-pagination-bullet-inactive-color: #fff !important;
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  width: 15px !important;
  height: 15px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25) !important;
}

.supplies-component {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-top: 15px;
  margin-bottom: 15px;

  .suplies-products-content {
    width: 100%;
    height: 100px;
    background: rgb(44, 44, 44, 1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: 12px;
    margin-bottom: 10px;

    &__img-container {
      width: 65px;
      height: 100%;

      img {
        height: 100%;
        width: 65px;
      }
    }

    &__img-title {
      font-family: 'Monsterrat Bold', sans-serif;
      font-size: 10px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      color: #fff;
      border-top-left-radius: 5px;
      min-height: 10px;
      background-color: #ffbb21;
      position: absolute;
      z-index: 1;
      top: 0;
    }

    &__product {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px;
    }

    &__header {
      color: #fff;
      font-family: $font-family-bold;
      font-size: 14px;
      margin-left: 5px;
    }

    &__info {
      margin-left: 5px;
      color: #fff;
      font-family: $font-family-medium;
      font-size: 12px;
    }

    &__product-piece {
      width: 60px;
      height: 100%;
      background: #000;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      .plus {
        color: #fff;
        width: 100%;
        height: 33.3%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-bold;
        font-size: 24px;
      }

      .counter {
        background-color: #ffbb21;
        width: 90%;
        height: 33.3%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-bold;
        color: #fff;
        font-size: 20px;
        border-radius: 2px;
      }

      .minus {
        color: white;
        width: 100%;
        height: 33.3%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-bold;
        font-size: 24px;
      }
    }
  }
}

.vehicle-info-box {
  padding: 5px 10px;

  &__title {
    font-family: $font-family-bold;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: $color-white;
    margin-bottom: 5px;
  }

  &__description {
    font-family: $font-family-medium;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: $color-white;

    b {
      font-family: $font-family-bold;
    }
  }
}
