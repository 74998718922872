@import './helpers';

a:-webkit-any-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

*:-webkit-any-link {
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: content-box !important;
}

.custom-date-picker,
.custom-input,
.text-input,
.counter-input-group__counter,
.sc-pDgPE,
.hQZtEK,
.sc-kFDfur,
.jawgpa,
.sc-eeDRCY,
.fxEiDk,
.sc-dIfARi {
  *,
  *::before,
  *::after {
    box-sizing: border-box !important;
  }
}

.content-header {
  img {
    vertical-align: middle !important;
  }
}

.custom-date-picker {
  border-radius: 20px;

  label {
    font-family: $font-family-bold;
    font-size: 16px;
    color: white;
    padding: 5px 0;
  }

  span {
    display: none;
  }

  input {
    background: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    padding: 0;
    text-align: center;

    &::placeholder {
      color: $color-black;
      font-family: $font-family-bold;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
    }
  }

  .sc-gKPRtg {
    font-weight: 600;
  }
}

.sc-dIfARi {
  .sc-hHTYSt {
    width: 90% !important;
    margin: auto;
    border-radius: 10px !important;
  }
}

.fxOzKw {
  font-weight: 700;
}

.hLaXSa,
.eaCDAk {
  color: black;
}
.hWcvJH,
.scAwt {
  width: 30%;
  font-weight: 300 !important;
  color: black !important;
}

.sc-cZFQFd,
.bflkTn,
.gmnoprint,
.sc-crozmw,
.sc-bvLVNa {
  display: none;
}

.gm-control-active,
.gm-fullscreen-control {
  display: block !important;
}

.gm-style,
.rainbow-google-map_map-container,
.rainbow-google-map {
  border-radius: 12px;

  div {
    border-radius: 12px;
  }
}

.sc-gAigJI,
.HxHbX,
.sc-gFAWRd,
.dUNmEg {
  width: 30% !important;
  font-weight: 600 !important;

  &::placeholder {
    font-weight: 600 !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--inner-height);

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: scroll;

      .main-title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        margin: 10px;
        font-family: $font-family-bold;
        color: $color-black;
      }
      .content-body {
        width: 75%;
        height: 80%;
        background: $color-primary;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        max-width: 560px;
        min-height: 400px;
        max-height: 600px;
        border-radius: 12px;

        .divider {
          height: 1px;
          background-color: white;
          width: 90%;
        }

        .home-avatar {
          width: 124px;
          height: 124px;
          padding: 50px;
          border-radius: 50%;
        }

        .info-text-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .text-area {
          width: 90%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }

        .request-button-group {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;

          .request-button-group__title {
            font-family: $font-family-bold;
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            color: $color-white;
            margin-bottom: 5px;
          }
        }

        .description-box__transfer {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-family: $font-family-medium;
          font-size: 12px;
          line-height: 15px;
          font-weight: 500;
          color: $color-white;

          b {
            font-family: $font-family-bold;
            font-weight: 700;
          }

          .description-box__desc {
            margin-bottom: 15px;
          }
        }
      }

      .border {
        border: double 4px transparent;
        background-image: linear-gradient($color-primary, $color-primary),
          radial-gradient(circle at top, #ffbb21, #a8a8a8);
        background-origin: border-box;
        background-clip: padding-box, border-box;
      }
    }

    .okeep-logo {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100px;
        height: 40px;
        object-fit: cover;
      }
    }
  }
}
